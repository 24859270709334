import PropTypes from "prop-types"
import React from "react"

import Row from "../components/bootstrap/row"

const TimelineRow = ({ time, title, line, children}) => (
  <Row>
  <div className="d-flex">
    <div style={{borderRight: line, position: `relative`}}>
      <div style={{
        position: `absolute`,
        width: `15px`,
        height: `15px`,
        backgroundColor: `#D23464`,
        borderRadius: `50%`,
        right: `-8px`
      }}></div>
      <div className="pr-3 pr-sm-5" style={{marginTop: `-8px`}}>
        <h4>{time}</h4>
      </div>
    </div>
    <div className="flex-grow-1 mb-4">
      <div className="pl-3 pl-sm-5" style={{marginTop: `-8px`}}>
        <h4>{title}</h4>
        {children}
      </div>
    </div>
  </div>
  </Row>
)

TimelineRow.propTypes = {
  time: PropTypes.string,
  title: PropTypes.string
}

TimelineRow.defaultProps = {
  time: `0:00`,
  title: `Title`,
  line: `1px solid #fff`,
}

export default TimelineRow
