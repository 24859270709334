import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"

import Container from "../components/bootstrap/container"
import ImgAChisholmProfile from "./img-achisholm-profile"
import ImgDBrankinProfile from "./img-dbrankin-profile"
import ImgAdobeLogo from "./img-adobe-logo"
import ImgCipherLabLogo from "./img-cipherlab-logo"
import ImgDatbLogo from "./img-datb-logo"
import ImgIronMountainLogo from "./img-iron-mountain-logo"
import LakesideSoftwareLogoImage from "./lakeside-software-logo-image"
import NFONLogoImage from "./nfon-logo-image"
import NHSBSALogoImage from "./nhsbsa-logo-image"
import TimelineRow from "./timeline-row"
import ImgSBoydProfile from "./img-sboyd-profile"
import ImgRMcneilProfile from "./img-rmcneil-profile"
import ImgSTseProfile from "./img-stse-profile"
import ImgKGuthrieProfile from "./img-kguthrie-profile"
import ImgGCHQLogo from "./img-gchq-logo"
import ImgOfwatLogo from "./img-ofwat-logo"
import ImgDfELogo from "./img-dfe-logo"
import ImgGPALogoV2 from "./img-gpa-logo-v2"
import ImgJBaynesProfile from "./img-jbaynes-profile"
import ImgOleeoLogo from "./img-oleeo-logo"
import ImgPescadoLogo from "./img-pescado-logo"
import ImgJDavidProfile from "./img-jdavid-profile"
import ImgPrimenetLogo from "./img-primenet-logo"
import ImgZivverLogo from "./img-zivver-logo"
import ImgAPaulProfile from "./img-apaul-profile"
import ImgLPorterProfile from "./img-lporter-profile"
import ImgCCubeLogo from "./img-ccube-logo"

const Timeline = () => (
  <Container>
    <h3 className="py-4 text-center" style={{
      color: `#fff`,
      fontFamily: `Avenir`,
      fontWeight: `900`,
      fontSize: `2.5rem`
    }}>Conference &amp; Exhibition<br /><small>26th November 2020</small></h3>
    <TimelineRow time="09:00" title="Opening Address &amp; Explaining Smarter Working Live 2021">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgDBrankinProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Dominic Brankin</strong><br />
            <em>Operations Director</em><br />
            Government Property Agency
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="09:20" title="Smarter Working in a COVID World">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgAChisholmProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Alex Chisholm</strong><br />
            <em>Chief Operating Officer for the Civil Service and Permanent Secretary</em><br />
            Cabinet Office
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="09:40" title="Celebration of Places">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgSBoydProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Steven Boyd</strong><br />
            <em>Chief Executive</em><br />
            Government Property Agency
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="10:00" title="Celebration of People">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgRMcneilProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Rupert McNeil</strong><br />
            <em>Government Chief People Officer</em><br />
            Cabinet Office
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="10:20" title="Comfort Break" />
    <TimelineRow time="10:35" title="Celebration of Processes">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgSTseProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Simon Tse</strong><br />
            <em>Chief Executive</em><br />
            Crown Commercial Service
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="10:50" title="Celebration of Technology">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgJDavidProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Julian David</strong><br />
            <em>Chief Executive Officer</em><br />
            techUK
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="11:10" title="Smarter Working - Lessons Learnt from 2020">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgAPaulProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Antony Paul</strong><br />
            <em>Head of Global Product Marketing - BPA</em><br />
            Quadient
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="11:30" title="Celebrating Smarter Working &amp; Supporting Implementation">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgKGuthrieProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Kate Guthrie</strong><br />
            <em>Deputy Director Workplace Experience and Smarter Working Programme Director</em><br />
            Government Property Agency
          </p>
        </div>
      </div>
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgJBaynesProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Jenny Baynes</strong><br />
            <em>Workplace Experience Head of Business Change (Smarter Working)</em><br />
            Government Property Agency
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="12:00" title="Plenary Hangout">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgDBrankinProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Chaired by Dominic Brankin</strong><br />
            <em>Operations Director</em><br />
            Government Property Agency
          </p>
          <p>Join the plenary panelists and special guests for a breakout Q&amp;A session</p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="13:00" title="Workshops">
      <div className="py-1">
        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Digital Experience Management in the New Normal<br /><small>Lakeside Software and Department for Work and Pensions</small></h5>
              <div className="mx-3 my-2" style={{width: `150px`}}><OutboundLink href="https://www.lakesidesoftware.com" target="_blank" rel="noopener noreferrer"><LakesideSoftwareLogoImage /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            COVID-19 has changed the way we approach technology. Employees' workspaces are now diverse, due to a rise in home working, the IT environment is experiencing a myriad of challenges that did not previously exist. Issues have arisen around the limitations of home networks, bandwidth, the restrictions of license contracts, not to mention a spike in helpdesk requests and infrastructure that sits out of the control of IT. So how do we overcome our current workspace challenges? In this workshop, Lakeside Software and the Department of Work and Pensions will explore the toolkits available to support employees’ daily interaction with technology.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Prioritising Mental Health and Wellbeing<br /><small>Pescado, CIPD and PPMA</small></h5>
              <div className="mx-3 my-2" style={{width: `150px`}}><OutboundLink href="https://pescado.co.uk" target="_blank" rel="noopener noreferrer"><ImgPescadoLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            <p>During this time of crisis, it is more important than ever to place support for the mental health and wellbeing of our people at the heart of our organisation’s culture. Communication and collaboration play an integral part in this, as organisations across the public sector create imaginative ways to connect with staff including through virtual platforms like Microsoft Teams.</p>
            Join us at this workshop as Pescado, CIPD and PPMA explore how important communication and collaboration is for people management and that unified communication platforms are not just digital technology, they have become HR tools.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Digitalising the Future of Work<br /><small>NHS Business Services Authority</small></h5>
              <div className="mx-3 my-2" style={{width: `170px`}}><OutboundLink href="https://www.nhsbsa.nhs.uk" target="_blank" rel="noopener noreferrer"><NHSBSALogoImage /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Discussing the role Automation should play within the public sector is not new. Automation, at its core, is designed to surpass physical human contact and has proved an invaluable benefit to services during COVID-19. However, identifying which Government processes Automation and other AI applications are best suited to requires careful examination. This workshop will deliver thoughtful insight into the current processes where Automation is used across Government and how it may emerge in the future, in a variety of functions and forms.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Small Scale, Big Impact<br /><small>datb</small></h5>
              <div className="mx-3 my-2" style={{width: `100px`}}><OutboundLink href="https://www.datb.com" target="_blank" rel="noopener noreferrer"><ImgDatbLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            <p>The velocity and cost-effectiveness of ‘agile’ development methodologies can lower the cost and resourcing bars for tactical developments. This is good news all round... except when the tactical solution works so well that, over time, it becomes a critical system.</p>
            This is particularly true for projects launching during COVID-19, where small changes can transform the future. So, no matter the size or budget of your project, its potential strategic value cannot be overlooked. This workshop discusses how the selection of a platform tailored to the business of local government can ensure that every new development sits within a strategic architecture from the start, regardless of future requirements.
          </div>
        </div>
      </div>
    </TimelineRow>

    <TimelineRow time="13:30" title="Smarter Working Live Lounge">
      <div className="py-1">
        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>A Smarter Working Process – Continually Adapt to Continually Succeed<br /><small>Live Lounge 1</small></h5>
              <div className="mx-3 my-2" style={{width: `130px`}}><OutboundLink href="https://www.gchq.gov.uk" target="_blank" rel="noopener noreferrer"><ImgGCHQLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Government Communications Headquarters (GCHQ) have been striving towards smarter working excellence for several years now. As an early adopter of smarter working, their achievements led them to win the 2019 smarter working award in technology. Join Jo Cavan, Director Strategy, Policy and Engagement, GCHQ and Dave W, Smarter Working Transformation Lead, at the live lounge as GCHQ share how they overcame challenges to deliver transformative smarter working practices, and how they are continually driving new thinking for future ways of working.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Our Journey to Smarter Working Excellence <br /><small>Live Lounge 2</small></h5>
              <div className="mx-3 my-2" style={{width: `120px`}}><OutboundLink href="https://www.ofwat.gov.uk" target="_blank" rel="noopener noreferrer"><ImgOfwatLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Water Services Regulation Authority (Ofwat) were the first department in the Civil Service to achieve smarter working maturity. A huge achievement in itself, they have since become a beacon for smarter working good practice across the wider public sector. In this live lounge, Ofwat Senior Director, Lisa Commane, discusses their smarter working journey, from unpicking the process of how they were able to achieve all they did, to adapting an approach that is fit for the current climate.
          </div>
        </div>

      </div>
    </TimelineRow>

    <TimelineRow time="14:00" title="Workshops">
      <div className="py-1">
        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Taking Stock - Asset Management in Times of Crisis<br /><small>CipherLab</small></h5>
              <div className="mx-3 my-2" style={{width: `100px`}}><OutboundLink href="https://www.cipherlab.com" target="_blank" rel="noopener noreferrer"><ImgCipherLabLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            <p>COVID-19 has resulted in social distancing requiring staff to work remotely and business continuity becoming more important than ever. As its assets become more widely dispersed, Government are faced with the challenge to efficiently manage its resources to avoid damaging gaps in information, inaccuracies in data and barriers to revenue generation.</p>
            This workshop will discuss ways to innovate asset management, creating a quicker and more accurate picture of government's assets, moving asset tracking from a place of risk and vulnerability to a place of robust operational strength.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>People vs Talent: Building A Smarter Workforce<br /><small>Oleeo &amp; Staffordshire County Council</small></h5>
              <div className="mx-3 my-2" style={{width: `95px`}}><OutboundLink href="https://oleeo.com" target="_blank" rel="noopener noreferrer"><ImgOleeoLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            The public sector is facing new pressures in talent acquisition. COVID-19 has exposed shortcomings in the tools and processes that can attract/retain new talent in order to build a more adaptable workforce that exceeds capabilities. No more can public sector leaders in this space afford to rely on a reactive or traditional approaches to recruitment. They must look to Smarter Working and rely on data to meet the needs of the business and the changing expectations of candidates, recruiters, and hiring managers. Join HR experts Oleeo and their public sector guest speaker, as they explore a data-centric approach to talent acquisition and how this method means you are twice as likely to improve the quality of any future hire.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Enabling Operational Continuity for DHSC Records Management Team During Covid<br /><small>Iron Mountain and Department for Health and Social Care</small></h5>
              <div className="mx-3 my-3" style={{width: `160px`}}><OutboundLink href="https://www.ironmountain.co.uk" target="_blank" rel="noopener noreferrer"><ImgIronMountainLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            <p>During routine risk and business continuity assessments, a pandemic is only one scenario that is considered. Until this year, the scenario assumptions never had to be put in practice - and never at such rapid pace.</p>
            Working together to identify the critical records needed by the Department of Health and Social Care to meet their customers’ needs, Iron Mountain was able to respond quickly, enabling the now fully remote DHSC teams easy access to physical and digital records. This session will explain how this solution came together so quickly and the positive impact the implementation has had on the DHSC teams.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Driving Efficiency While Improving Citizen Experience<br /><small>Adobe and Police ICT</small></h5>
              <div className="mx-3 my-1" style={{width: `35px`}}><OutboundLink href="https://acrobat.adobe.com/uk/en/sign.html" target="_blank" rel="noopener noreferrer"><ImgAdobeLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            As the world continues to adjust to remote working, public sector organisations increasingly face pressure from citizens to deliver seamless digital interactions. To keep up with changing demands, organisations need to improve operational efficiency and embrace digital transformation to support the delivery of key services. In this workshop, we’ll demonstrate how tools like Adobe Sign can make document processes smarter and more efficient, and how in doing so, the concept of efficiency can be much more than cost savings. We’ll be joined by Police ICT, who will share how everyday police work has been transformed by making document management and approvals faster and more efficient in various areas such as enabling remote witness statements.
          </div>
        </div>
      </div>
    </TimelineRow>

    <TimelineRow time="14:30" title="Smarter Working Live Lounge">
      <div className="py-1">
        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Smarter Working People - Shaping a Compassionate Culture<br /><small>Live Lounge 1</small></h5>
              <div className="mx-3" style={{width: `90px`}}><OutboundLink href="https://www.gov.uk/government/organisations/department-for-education" target="_blank" rel="noopener noreferrer"><ImgDfELogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Department for Education (DfE) have invested significantly in their approach to the smarter working programme. Championing learning and development, as well as putting people first, has had a profound impact on the culture and people of DfE. At this live lounge, join us as DfE share how compassionate leadership and support of employees has set a flourishing foundation for smarter working.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>In an evolving world, how is Smarter Working evolving?<br /><small>Live Lounge 2</small></h5>
              <div className="mx-3" style={{width: `65px`}}><OutboundLink href="https://www.gov.uk/government/organisations/government-property-agency" target="_blank" rel="noopener noreferrer"><ImgGPALogoV2 /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            In a completely different style, at this live lounge the audience will use their smarter working experiences and varying knowledge areas to engage in a hackathon type discussion. Key focus points will be on how ways of working have changed in 2020, and what wider implications the current climate will have on smarter working in the future. More than that, we will investigate how organisations can effectively adapt their smarter working plans in 2021 and the years beyond.
          </div>
        </div>

      </div>
    </TimelineRow>

    <TimelineRow time="15:00" title="Workshops">
      <div className="py-1">
        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Efficiency in your Estates<br /><small>CCube Solutions &amp; NHS Property Services</small></h5>
              <div className="mx-3 my-2" style={{width: `180px`}}><OutboundLink href="https://ccubesolutions.com" target="_blank" rel="noopener noreferrer"><ImgCCubeLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Navigating the best ways to get the most out of workplaces and workspaces have proved a challenge for many organisations. During this workshop, we'll hear the views of Dr Vijay Magon of CCube Solutions, who’ll share how available technology can support organisations to re-purpose their spaces in innovative ways, leverage a wide range of efficiencies, and support remote working. Vijay will be joined by NHS Property Services, who’ll present their involvement with the NHS Estate and share the ways in which ‘Smarter Working’ can make a real difference.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>The Smart Approach to Cloud Technology<br /><small>Primenet and ThinkOn</small></h5>
              <div className="mx-3 my-2" style={{width: `115px`}}><OutboundLink href="https://www.primenetuk.com" target="_blank" rel="noopener noreferrer"><ImgPrimenetLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Delivered by Primenet’s Canadian-based partners, ThinkOn, this innovation exchange explores how the UK public sector can adopt a ‘smarter approach’ to managing Cloud Technology & Data. Learn why this is more important than ever before and how you can maximise the value of your existing Cloud solutions. Our host, the CEO of ThinkOn, will share the lessons and experiences of public sector organisations in Canada and what approach you should take to achieve far greater outcomes.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`,
                padding: ``
              }}>Enabling Safe &amp; Compliant Digital Communication<br /><small>Zivver &amp; The Convention of Scottish Local Authorities (COSLA)</small></h5>
              <div className="mx-3 my-2" style={{width: `130px`}}><OutboundLink href="https://www.zivver.com" target="_blank" rel="noopener noreferrer"><ImgZivverLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            With a surge in daily electronic communications, especially those exchanging sensitive data within and between public sector organisations, it’s not a question of whether a mistake will occur but how to minimise risk and mitigate impact. Join this workshop where Douglas Shirlaw, Chief Digital Officer for The Convention of Scottish Local Authorities (COSLA), interviews Rick Goud, CIO of Zivver, to review the most common causes of data breaches and how this has evolved during Covid.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Smarter Working Underpinned By Unified Communications<br /><small>NFON and Motor Neurone Disease Association</small></h5>
              <div className="mx-3 my-1" style={{width: `32px`}}><OutboundLink href="https://www.nfon.com/gb/" target="_blank" rel="noopener noreferrer"><NFONLogoImage /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            More organisations than ever before are now enabling their staff to enjoy the benefits of Smarter Working, from agile working environments to greater productivity through collaboration, video conferencing and enterprise cloud telephony. As employees & customers alike benefit from deeper levels of engagement & increased levels of customer services, join communication experts NFON and their customer The Motor Neurone Disease Association, who’ll share their experiences of how Smarter Working is supporting the organisation from day to day communications to fund raising activities.
          </div>
        </div>
      </div>
    </TimelineRow>

    <TimelineRow time="15:30" title="Smarter Working Live Lounge">
      <div className="py-1">
        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Assessing the priorities of post-COVID workspaces<br /><small>Live Lounge 1</small></h5>
              <div className="mx-3" style={{width: `65px`}}><OutboundLink href="https://www.gov.uk/government/organisations/government-property-agency" target="_blank" rel="noopener noreferrer"><ImgGPALogoV2 /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Like many other organisations, COVID-19 has forced us to rethink our relationship with the workplace. How can we best utilise them to provide the right spaces for the right uses? And how can we gain a better understanding of what we need from our spaces, in order to make this decision? Join us at this collaborative live lounge as we analyse not only how we can reimagine our workplaces, but also how we can level up our approach to create truly great places to work that are adaptable to our changing needs.
          </div>
        </div>

        <div className="media my-3 p-3" style={{
          backgroundColor: `#fff`
        }}>
          <div className="media-body" style={{
            color: `#30c4bf`,
            fontSize: `.9rem`,
            fontFamily: `Din Pro`
          }}>
            <div className="d-flex">
              <h5 className="flex-grow-1" style={{
                color: `#d23464`,
                fontWeight: `bold`
              }}>Lean in 15 with Beth Farminer, Ofwat’s Smarter Working Lead<br /><small>Live Lounge 2</small></h5>
              <div className="mx-3 my-2" style={{width: `120px`}}><OutboundLink href="https://www.ofwat.gov.uk" target="_blank" rel="noopener noreferrer"><ImgOfwatLogo /></OutboundLink></div>
            </div>
            <hr style={{
              backgroundColor: `#d23464`,
              margin: `1rem 0`
            }} />
            Join Beth Farminer, Smarter Working lead at Ofwat, as she leads the group through a Continuous Improvement tools and techniques live lounge. She’ll talk through ways to make your day go better, faster, happier. 
          </div>
        </div>

      </div>
    </TimelineRow>
    
    <TimelineRow time="16:00" title="Conference Close" line="false" />
    <br />
    <h3 className="py-4 text-center" style={{
      color: `#fff`,
      fontFamily: `Avenir`,
      fontWeight: `900`,
      fontSize: `2.5rem`
    }}>Awards Ceremony</h3>
    <TimelineRow time="18:00" title="Opening Speech" />
    <TimelineRow time="18:15" title="Ceremony">
      <div className="d-flex pt-3">
        <div className="pr-4">
          <div style={{
            borderRadius: `50%`,
            border: `2px solid #fff`,
            width: `75px`,
            height: `75px`,
            overflow: `hidden`
          }}>
            <ImgLPorterProfile />
          </div>
        </div>
        <div>
          <p>
            <strong>Lucy Porter</strong><br />
            <em>Host</em><br />
            Comedian
          </p>
        </div>
      </div>
    </TimelineRow>
    <TimelineRow time="19:30" title="Close" line="false" />
  </Container>
)

export default Timeline
