import React from "react"

const GridLines = ({children, gridColor}) => (
  <div style={{
    position: `relative`
  }}>
    <div style={{
      position: `absolute`,
      width: `100%`,
      height: `100%`,
      background: `repeating-linear-gradient(-45deg, ${gridColor}, ${gridColor} 4px, transparent 4px, transparent 10px)`,
      top: `-1rem`,
      right: `-1rem`,
      zIndex: `-1`
    }} />
    {children}
  </div>
)

GridLines.defaultProps = {
  gridColor: `#000`
}

export default GridLines
